.splash-container{
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding: 50px;
}

.splash-no-data { 
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    width: 100%;
    height: 400px;
}