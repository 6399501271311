@import '../../contants/variables';

.formModal{

    .field-container{
        display: flex;
        align-items: center;
        margin:10px 0px;
        .field-label{
            margin: 0px 20px 0px 0px;
            width: 200px;
        }
    }

    .inclusions-container{
        .inclusion{
            background: $offwhite;
            border-radius: 5px;
            padding: 10px;
            margin: 5px 0px;
        }
        .ant-checkbox-checked .ant-checkbox-inner,
        .ant-checkbox-checked .ant-checkbox-inner:focus {
            background-color: $primary;
            border-color: $primary;
        }
    }

    .inclusion-container{
        background: $offwhite;
        margin: 5px 0px;
        padding: 5px 10px;
        border-radius: 5px;
        p{
            margin: 0;
        }
    
        .icon-container{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .icon-button{
                height: 25px;
                width: 25px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                transform: scale(1);
                transition: 0.2s;
                cursor: pointer;
                margin-left: 10px;
        
                &:hover{
                    background: $offwhite;
                    transform: scale(1.2);
                    transition: 0.2s;
                }
                &.edit{
                    svg{
                        color: $secondary;
                    }
                }
                &.delete{
                    svg{
                        color: $red;
                    }
                }
        
                svg{
                    height: 20px;
                    width: 20px;
                }
            } 
        }
    }



    .react-datepicker__input-container {
        width: inherit;
      }
      
    .ant-space,
    .ant-space-item,
    .react-datepicker-wrapper {
        width: 100%;
    }  

    .ant-modal-title{
        color: $primary;
    }
    .ant-btn-primary{
        background: $primary !important;
    }
    .data-name{
        color: $primary;
        font-weight: bold;
    }
}