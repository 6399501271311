@import '../contants/variables';

.article-container{
    // border: 1px solid $black;
    margin: 20px;

    .article-header{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .article-filter{
            display: flex;
            align-items: center;
        }

        .article-btn{
            display: flex;
            align-items: center;
        }

        .search-field-div{
            width: 250px;
            margin-right: 10px;
        }

        .btn-div{
            margin-left: 10px;
            width: 200px;
            svg{
                margin-right: 5px;
                height: 20px;
                width: 20px;
            }
        }
    }
    .article-card-div{
        margin-top: 20px;
        
        .article-card-container{
            background: $white;
            border-radius: 5px;
            overflow: hidden;
            position: relative;
            .article-card-header{
                height: 150px;
                overflow: hidden;
                img{
                    // height: 100%;
                    width: 100%;
                }
                .section{
                    position: absolute;
                    background: $white;
                    color: $black;
                    top: 0;
                    right: 0;
                    padding: 5px 10px;
                    border-bottom-left-radius: 5px;
                    p{
                        margin:0;
                        font-weight: bold;
                    }
                }
            }
            .article-card-body{
                padding: 10px 20px;
                P{
                    margin: 0;
                }
                .title{
                    font-weight: bold;
                    height: 45px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .date{
                    font-size: 10px;
                    color: $gray;
                }
                .introduction{
                    margin-top: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                }
            }
            .article-card-footer{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom:10px;
                padding: 0px 20px;
                .icon-button{
                    height: 25px;
                    width: 25px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transform: scale(1);
                    transition: 0.2s;
                    cursor: pointer;
            
                    &:hover{
                        background: $offwhite;
                        transform: scale(1.2);
                        transition: 0.2s;
                    }
            
                    &.view{
                        svg{
                            color: $yellow;
                        }
                    }
                    &.edit{
                        svg{
                            color: $secondary;
                        }
                    }
                    &.delete{
                        svg{
                            color: $red;
                        }
                    }
            
                    svg{
                        height: 20px;
                        width: 20px;
                    }
                } 
            }
        }
        
    }
}