@import "../contants/variables";

.nav-body {
  background-color: $white;
  height: 100vh;
  width: 100%;
  overflow: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $lightgray;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $primary;
  }
}

.ant-drawer-header {
  padding: 15px 10px;
  border-bottom: 1px solid #f0f0f0;
  .ant-drawer-header-title {
    justify-content: flex-end;
    button {
      margin-right: 0;
      padding: 0;
    }
  }
}

.ant-drawer-body {
  .nav-body {
    height: unset;
  }
}

.nav-container {
  padding: 10px;
  margin-top: 10px;
  letter-spacing: 0.1em;
  font-size: 14px;

  .menu-item-wrapper {
    &.open {
      background: #f1e8e8;
      border-radius: 0 0 5px 5px;
      .navlink-cars-container {
        background-color: rgba($color: $secondary, $alpha: 0.3);
      }
      .navlink-cars-container.active {
        background-color: $primary;
        border-radius: 5px 5px 0 0;
      }
    }
  }
  .navlink-container {
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
      color: $black;
      font-weight: bold;
      text-transform: uppercase;
      margin: 0;
    }
    svg {
      color: $black !important;
      margin-right: 10px;
      height: 25px;
      width: 25px;
    }
  }
  .navlink-cars-container {
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    text-align: left;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
      font-weight: bold;
      text-transform: uppercase;
      margin: 0;
      flex: 1;
      color: $black;
    }
    svg {
      color: $black !important;
      margin-right: 10px;
      height: 25px;
      width: 25px;
    }
    .right-icon-div {
      background-color: red;
    }
  }
  .navlink-cars-container.active {
    background-color: $primary;
    p {
      color: $white;
    }
    svg {
      color: $white !important;
    }
  }

  .sub-link-container {
    padding: 10px 10px 1px 45px;
    .sub-link {
      p {
        text-transform: uppercase;
        font-weight: 500;
        color: $gray;
        font-size: 14px;
        margin-bottom: 8px;
      }
    }
    .sub-link.active {
      div {
        p {
          color: $primary;
          font-weight: bold;
        }
      }
    }
    .sub-link:hover {
      div {
        p {
          text-decoration: underline;
        }
      }
    }
  }

  .left-link-icon {
    width: 10px;
    height: 10px;
    background-color: $black;
    margin-right: 10px;
  }
  .right-link-icon {
    width: 10px;
    height: 10px;
    background-color: $black;
    margin-left: 10px;
  }
}

.normal.active {
  div {
    background-color: $primary;
    p {
      color: $white;
    }
    .left-link-icon {
      background-color: $white;
    }
    svg {
      color: $white !important;
    }
  }
}

.navlink-container:hover {
  background-color: rgba($color: $secondary, $alpha: 0.3);
}

.navlink-cars-container:hover {
  background-color: rgba($color: $secondary, $alpha: 0.3);
}
