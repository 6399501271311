@import '../../contants/variables';

.header-card{
    height: 130px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;

    &.verified{
        background-image: linear-gradient(to top right ,  #2C9C22 , #33EB23 );
    }
    &.unverified{
        background-image: linear-gradient(to top right ,  #792121 , #EA3636 );
    }
    &.sold{
        background-image: linear-gradient(to top right ,  #224F76 , #337AB7 );
    }
    &.draft{
        background-image: linear-gradient(to top right ,  #f42020 , #bc0e0e );
    }
    &.active{
        background-image: linear-gradient(to top right ,  #0E6CBC , #20A8F4 );
    }
    &.rental-unverified {
        background-image: linear-gradient(to top right ,  #bc680e , #f4aa20 );
    }
    &.bid-verified{
        background-image: linear-gradient(to top right ,  #227f9c , #23ebe0 );
    }
    &.bid-unverified{
        background-image: linear-gradient(to top right ,  #94229c , #e623eb );
    }
    &.bid-sold{
        background-image: linear-gradient(to top right ,  #9c9622 , #e6eb23 );
    }
    &.total-carlist{
        background-image: linear-gradient(to top right ,  #bc0e0e , #f42020 );
    }

    &.total-verified-accessory{
        background-image: linear-gradient(to top right ,  #cd6b33, #c293dd );
    }
    &.total-unverified-accessory{
        background-image: linear-gradient(to top right , #c293dd,  #cd6b33 );
    }
    
    &.total-banner{
        // background-image: linear-gradient(to top right ,  #abbc0e , #f4ea20 );
        // background-image: linear-gradient(to top right ,  #bc7a0e , #f4ca20 );
        background-image: linear-gradient(to top right ,  #373030 , #c1bfb6 );
    }

    p{
        font-size: 2em;
        font-weight: bold;
        color: $white;
        margin:0;
        flex:1;
        line-height: 40px;
    }

    svg{
        color: $white;
        height: 60px;
        width: 60px;
    }
}

.body-card{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;

    p{
        margin:0;
        font-size: 20px;
        font-weight: bold;

        &.verified-text{
            color: #2C9C22;
        }
        &.unverified-text{
            color: #792121;
        }
        &.sold-text{
            color: #224F76;
        }
        &.active-text{
            color: #0E6CBC;
        }
    }

    .goIcon{
        background:#f2f2f2;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transform: 0.2s;
        transform: scale(1);

        svg{
            color: $secondary !important;
            height: 20px;
            width: 20px;
        }

        &:hover{
            background:$secondary !important;
            transition: 0.2s;
            transform: scale(1.2);
            svg{
                color: $white !important;
            }
        }
    }
}