@import '../../contants/variables';

.form-table-container{
    margin: 30px 20px;
    padding: 20px;
    background : $white;
    border-radius: 5px;
    box-shadow: 2px 2px 4px $gray;
    position: relative;
    
    .table-title-icon{
        position: absolute;
        top: -30px;
        padding: 10px;
        border-radius: 5px;

        &.add {
            background :$primary;
        }
        &.edit {
            background :$secondary;
        }

        svg{
            height: 70px;
            width: 70px;
            color: $white;
        }
    }

    .table-title-text{
        margin-left: 100px;
        font-weight: bold;
        font-size: 25px;

        &.add {
            color :$primary;
        }
        &.edit {
            color :$secondary;
        }
    }

    .header-container{
        margin:10px 0px;
        .header{
            font-weight: bold;
            color: $black;
        }
        .sub-header{
            font-weight: 500;
            color: gray;
        }
    }

    p{
        margin:0
    }

    .form-body-container{
        margin-top: 30px;
    }

    .form-fields{
        padding: 10px 20px 10px 0px;
        .form-field-label{
            margin-bottom: 10px;

            span{
                color:$red
            }
        }
        .label-with-tooltip {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            svg {
                cursor: pointer;
                margin-bottom: 10px;
            }
        }
    }
    .form-radio{
        display: flex;
        flex-direction: row;
        align-items: center;
        p{
            margin-bottom:0;
            margin-right: 10px;
        }
    }

    .package-card-container{
        background: $offwhite;
        margin: 10px 20px 10px 0px;
        padding: 10px;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        p{
            font-weight: bold;
            font-size: 18px;
            color: $black;
        }

        &.active{
            background:$primary;
            p{
                color:$white
            }
        }
        .package-title{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .fieldPrefix{
        background:$lightgray;
        // height: 22px;
        width: 25px;
        // text-align: center;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        p{
            color: $white;
            font-size: 12px;
            
        }
    }

    .upload-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        p{
            color: $black;
        }
        .header-text{
            font-weight: bold;
            font-size: 16px;
        }
        .header-div{
            width: 70%;
        }
        .download-div{
            width: 250px;
        }
    }

    .upload-container{
        height: 200px;
        border:2px dashed $gray;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.col-div{
            margin-right: 20px;
            margin-bottom: 20px;
        }

        svg{
            height: 50px;
            width: 50px;
            color: $gray;
        }
        p{
            margin-top: 10px;
            font-weight: bold;
            font-size: 16px;
            color: $gray;
        }
    }
    .image-container{
        height: 200px;
        border:2px solid $black;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img{
            max-height: 100%;
            max-width: 100%;
            object-position: center;
            object-fit: cover;
        }
        &.col-div{
            margin-right: 20px;
        }

        .removeIcon{
            height: 30px;
            width: 30px;
            background: $red;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0%;
            right: 0%;
            cursor: pointer;
            svg{
                color: $white;
                height: 20px;
                width: 20px;
            }
        }
        .downloadIcon{
            height: 30px;
            width: 30px;
            background: $green;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 31px;
            right: 0%;
            cursor: pointer;
            svg{
                color: $white;
                height: 20px;
                width: 20px;
            }
        }
    }

    .upload-error-text{
        margin-bottom: 10px;
        color: $red;
    }

    .preview-text-container{
        .preview-header{
            font-size: 20px;
            color: $primary;
            margin-bottom: 10px;
        }
        .preview-note{
            font-size: 18px;
            margin-top: 20px;
        }
        .button-container{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 250px;
            button{
                margin-top: 20px;
                // margin-left: 20px !important;
                // width: 80% !important;
            }
        }
    }

    .preview-container{
        margin:10px;
        padding: 20px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f2f2f2;
        flex-direction: column;
        .mobile-view-container{
            width: 360px;
            height: 700px;
            border-top: 25px solid $black;
            border-left: 10px solid $black;
            border-right: 10px solid $black;
            border-bottom: 50px solid $black;
            border-radius: 5px;
            overflow-y: scroll;
            /* Hide scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                display: none;
            }
            /* Hide scrollbar for IE, Edge and Firefox */
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
            
            .image-container{
                width: 100%;
                height: 200px;
                border: 0;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .header-name-container{
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $white;
                padding: 10px 20px;
                margin-bottom: 10px;

                .name{
                    color: $black;
                    font-weight: bold;
                    font-size: 18px;
                    margin:0px;
                }
                .price{
                    color: $primary;
                    font-weight: bold;
                    font-size: 20px;
                }
                svg{
                    color: $gray;
                    height: 20px;
                    width: 20px;
                }
            }

            .technical-specs-container{
                background: $white;
                padding: 10px 20px;
                margin-bottom: 10px;
                .divider{
                    margin:2px 0px !important;
                    background-color:$lightgray;
                }
                .spec-container{
                    display: flex;
                    align-items: center;
                    svg{
                        color: $black;
                    }
                    p{
                        color: $black;
                        margin: 0px 0px 0px 5px;
                    }
                    .price-icon{
                        background: $black;
                        padding: 0px 5px;
                        margin: 2px 0px;
                        border-radius: 2px;
                        text-align: center;
                        p{
                            color: $white;
                            font-weight: bold;
                            font-size: 12px;
                            margin: 0px;
                        }
                    }
                }
            }
            .details-container{
                background: $white;
                padding: 0px 20px 20px 20px;
                min-height: 300px;
                margin-bottom: 10px;
            }
            .review-container{
                background: $white;
                padding: 10px 20px;
                .review-title{
                    font-size: 20px;
                    color: $primary;
                    margin-bottom: 10px;
                }
                .comments{
                    color: $gray;
                    margin-top: 5px;
                }
            }
        }

        .web-view-container{
            width: 100%;
            height: 400px;
            border:10px solid $black;
            border-radius: 10px;
            background: $white;
            overflow-y: scroll;
            /* Hide scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                display: none;
            }
            /* Hide scrollbar for IE, Edge and Firefox */
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
  
            .image-container{
                width: 100%;
                height: 200px;
                border: 0;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .product-container{
                padding: 10px;
                border:none;
                .header-name-container{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .type{
                        color: $gray;
                        font-size: 12px;
                        margin-bottom: -10px;
                        // line-height: 0px;
                    }
                    .name{
                        font-size: 20px;
                        font-weight: bold;
                    }
                    .price{
                        font-size: 20px;
                        color: $primary;
                        font-weight: bold;
                    }
                    .slider-icon-container{
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        .slider-icon{
                            background: $gray;
                            height: 15px;
                            width: 15px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-left: 5px;
                            margin-top: 5px;
                            svg{
                                color: $white;
                                height: 10px;
                                width: 10px;
                            }
                        }
                    }
                }
                .header-section{
                    font-weight: bold;
                    color: $black;
                    margin: 10px 0px;
                }

                .tech-specs-container{
                    display: flex;
                    align-items: flex-start;
                    background: $offwhite;
                    padding: 10px;
                    .tech-spec-label{
                        margin:0;
                        font-size: 10px;
                        margin-left: 5px;
                        font-weight: bold;
                    }
                    .tech-spec-value{
                        margin-left: 5px;
                        font-weight: bold;
                        color: $primary;
                    }
                    
                }
                .spec-container{
                    display: flex;
                    align-items: center;
                    background: $offwhite;
                    padding: 10px;
                    svg{
                        color: $black;
                    }
                    p{
                        color: $black;
                        margin: 0px 0px 0px 5px;
                    }
                    .price-icon{
                        background: $black;
                        padding: 0px 5px;
                        margin: 2px 0px;
                        border-radius: 2px;
                        text-align: center;
                        p{
                            color: $white;
                            font-weight: bold;
                            font-size: 12px;
                            margin: 0px;
                        }
                    }
                }
            }
            .sides-container{
                padding: 10px;
                .side-div{
                    height: 150px;
                    background: $offwhite;
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding:20px;
                    text-align: center;
                }
            }
        }
    }


    //tabs
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: $primary !important; 
        font-weight: 500;
    }

    .ant-tabs-ink-bar {
        position: absolute;
        background: $primary;
        pointer-events: none;
    }

    //radio-color
    .ant-radio-checked .ant-radio-inner{
        border-color: $primary !important ;
    }
    
    .ant-radio-checked .ant-radio-inner:after{
        background-color: $primary;
    }
    
    .ant-radio:hover .ant-radio-inner {
        border-color: $primary ;
    }
    .ant-radio-checked .ant-radio-inner:focus{
        border-color: $primary;
    }
    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-checked .ant-checkbox-inner:focus {
        background-color: $primary;
        border-color: $primary;
    }

    .react-datepicker__input-container {
        width: inherit;
      }
      
    .ant-space,
    .ant-space-item,
    .react-datepicker-wrapper {
        width: 100%;
    }

    .ant-picker-clear{
        display: none !important;
    }
}
