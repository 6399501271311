@import '../contants/variables';

.brand-table-container{
    margin: 30px 20px;
    padding: 20px;
    background : $white;
    border-radius: 5px;
    box-shadow: 2px 2px 4px $gray;
    position: relative;

    .add-button-container{
       margin-right: 20px;
       display: flex;
       align-items: center;
       svg{
           height: 20px;
           width: 20px;
           margin-right: 5px;
       }
    }

    .table-div{
        margin-top: 30px;
    }

    .search-container{
        margin-top:40px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
    
    .table-title-icon{
        position: absolute;
        top: -30px;
        padding: 10px;
        border-radius: 5px;

        &.primary {
            background :$primary;
        }

        svg{
            height: 70px;
            width: 70px;
            color: $white;
        }
    }

    .table-title-text{
        margin-left: 100px;
        font-weight: bold;
        font-size: 25px;

        &.primary {
            color :$primary;
        }
    }
}

.models-container{
    padding: 20px 40px;
    .model-container{
        background: $white;
        padding: 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 1px 1px 5px $lightgray;
        p{
            margin:0;
            color: $black;
        }
    }
}

.actions-icons-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    .icon-button{
        height: 25px;
        width: 25px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: scale(1);
        transition: 0.2s;
        cursor: pointer;

        &:hover{
            background: $offwhite;
            transform: scale(1.2);
            transition: 0.2s;
        }

        &.add{
            svg{
                color: $green;
            }
        }
        &.edit{
            svg{
                color: $secondary;
            }
        }
        &.delete{
            svg{
                color: $red;
            }
        }

        svg{
            height: 20px;
            width: 20px;
        }
    } 
}
.ant-spin-dot-item{
    background: $primary !important;
}

.userModal{

    .ant-modal-title{
        color: $primary;
    }
    .ant-btn-primary{
        background: $primary !important;
    }
    .data-name{
        color: $primary;
        font-weight: bold;
    }
}