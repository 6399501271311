@import '../contants/variables';

.body-container{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.login-container{
    background: $white;
    // height: 400px;
    width: 500px;
    padding: 0px 10px;
    border-radius: 5px;
    box-shadow: 2px 2px 4px gray;
    .error-text{
        color: $red;
    }
    .logo-container{
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 30px;
    }
    .form-container{
        padding:40px 50px;
    }
    .forgot-container{
        margin-bottom: 10px;
        text-align: right;
        .forgot-password-hlink{
            color: $secondary;
            cursor: pointer;
        }
        .forgot-password-hlink:hover{
            color: $tertiary;
        }
    }
    .otp-form-container{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
    .otp-header{
        text-align: center;
        color: $primary;
        font-size: 20;
        font-weight: 500;
        margin-bottom: 20px;
    }
}

.top-header {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
}

.chip-container{
    background: $white;
    border:2px solid $primary;
    width: auto;
    border-radius: 50px;
    padding: 2px;
    cursor: pointer;
    .avatar{
        margin-right: 10px;
        background:$primary
    }
    p{
        font-weight: 500;
        color: $primary;
        font-size: 15px;
    }
    svg{
        color: $primary !important;
        height: 25px;
        width: 25px;
        margin-left: 10px;
    }
}
.menu-container{
    background: $white;
    text-align: right;
    padding: 10px 0px;
    border-radius: 5px;
    box-shadow: 2px 2px 4px $gray;
    
    

    .link-body{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        p{
            margin:10px;
            font-size: 15px;
            cursor: pointer;
            color: $black !important;
        }

        svg{
            color:$black !important;
            margin-right: 10px;
            height: 20px;
            width: 20px;
        }
    }

    .link-body:hover{
        background: $primary;
        display: flex;
        flex-direction: row;
        align-items: center;
        p{
            color: $white !important;
        }

        svg{
            color:$white !important;
        }
    }
}

.drawer-close-btn-wrapper {
    text-align: center;
    padding: 10px;
}

.ant-drawer-content-wrapper {
    .ant-drawer-body {
        padding: 0;
    }
}

.burger-menu-btn {
    display: none;
}

@media only screen and (max-width: 767px) {
    .top-header {
        justify-content: space-between;
    }
    .burger-menu-btn {
        display: block;
    }
}