@import '../../contants/variables';

.career-form-container{
    margin: 20px;
    padding: 20px;
    background: $white ;
    position: relative;
    .table-title-icon{
        position: absolute;
        top: -30px;
        padding: 10px;
        border-radius: 5px;

        &.add {
            background :$primary;
        }
        &.edit {
            background :$secondary;
        }

        svg{
            height: 70px;
            width: 70px;
            color: $white;
        }
    }

    .table-title-text{
        margin-left: 100px;
        font-weight: bold;
        font-size: 25px;

        &.add {
            color :$primary;
        }
        &.edit {
            color :$secondary;
        }
    }
    .form-container{
        margin-bottom: 20px;
        .form-fields{
            padding: 10px 20px 10px 0px;
            display: flex;
            .form-field-label{
                margin-bottom: 10px;
                width: 200px;
                span{
                    color:$red
                }
            }
        }
    }

    .tags-container{
        margin-left: 145px;
        .tag-container{
            background: $primary;
            padding: 5px 10px;
            color: $white;
            display: inline-flex;
            border-radius: 5px;
            margin-right: 10px;
            margin-bottom: 10px;
            p{
                margin:0;
            }
            svg{
                height: 20px;
                width: 20px;
                margin-right: 5px;
                cursor: pointer;
            }
        }
    }

    .upload-container{
        height: 200px;
        border:2px dashed $gray;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.col-div{
            margin-right: 20px;
            margin-bottom: 20px;
        }

        svg{
            height: 50px;
            width: 50px;
            color: $gray;
        }
        p{
            margin-top: 10px;
            font-weight: bold;
            font-size: 16px;
            color: $gray;
        }
    }

    .local-image{
        width: 100%;
        height: auto;
        overflow: hidden;
        position: relative;
        img{
            width: 100%;
            height: auto;
        }
        .erase-image{
            position: absolute;
            right: 0;
            top: 0;
            background: $white;
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            svg{
                height: 30px;
                width: 30px;
                &:hover{
                    color: $red;
                }
            }
        }
    }

    .btn-div{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 10px;
        .btn{
            margin-left: 15px;
            width: 250px;
        }
    }

   
}