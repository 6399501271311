@import '../contants/variables';

.package-container{
    // border: 1px solid $black;
    margin: 20px;

    .package-header{
        display: flex;
        align-items: center;

        .search-field-div{
            width: 300px;
        }

        .btn-div{
            margin-left: 10px;
            width: 200px;
            svg{
                margin-right: 5px;
                height: 20px;
                width: 20px;
            }
        }
    }
    .packages-card-body{
        margin-top: 20px;
        
        .package-card-container{
            background: $white;
            border-radius: 5px;
            overflow: hidden;
            .package-card-header{
                background: $primary;
                padding: 10px 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                p{
                    margin:0px;
                    color: $white;
                    font-weight: bold;
                    font-size: 18px;
                }
                .icon-buttons{
                    display: flex;
                    align-items: center;
                    svg{
                        height: 20px;
                        width: 20px;
                        color: $white;
                        cursor: pointer;
                        margin-left: 10px;
                        transform: scale(1);
                        transition: 0.2s;
                    }
                    svg:hover{
                        color: $secondary;
                        transform: scale(1.2);
                        transition: 0.2s;
                    }
                }
            }

            .package-price-container{
                display: flex;
                align-items: center;
                justify-content: center;
                color: $green;
                font-size: $green;
                padding:10px;
                font-size: 30px;
                font-weight: bold;
                p{
                    margin: 0;
                    span{
                        font-size: 10px !important;
                        color: $black !important;
                        font-weight: 500;
                    }
                }
            }
            .package-inclusion-container{
                padding: 0px 20px 20px 20px;
                font-weight: bold;
                .inclusion-div{
                    display: flex;
                    align-items: center;
                    background: $offwhite;
                    margin: 5px 0px;
                    padding: 5px 10px;
                    border-radius: 5px;
                    p{
                        margin: 0;
                        margin-left: 10px;
                        color: $black;
                        font-weight: 500;
                    }
                    svg{
                        height: 25px;
                        width: 25px;
                    }
                }
            }
        }
        
    }
}