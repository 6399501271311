@import '../../contants/variables';

.formModal{

    .form-container{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        &.contact-number {
            margin-bottom: 0;
        }
        &.description {
            margin-bottom: 10px;
            p {
                margin: 0;
            }
        }

        &.error-content {
            margin-top: -10px;
            margin-bottom: 10px;
            p {
                margin: 0;
            }
        }

        &.tags-container {
            margin: 0;
            p {
                margin: 0;
            }
        }
        .form-label{
            margin: 0;
            width: 40%;
            span{
                color : $red;
            }
        }
    }

    .react-tel-input{
        width: 100% !important;
        // height: 100% !important;
        // z-index: 10;
        .form-control{
            width: 100% !important;
            // height: 100% !important;
            border: 1px solid #e5e5ea !important;
        }
        .form-control:disabled{
            background-color: $offwhite;
        }
    }

    .permision-label {
        span{
            color : $red;
        }
    }
    .permission-checklist{
        padding: 10px;

        .ant-checkbox-checked .ant-checkbox-inner,
        .ant-checkbox-checked .ant-checkbox-inner:focus {
            background-color: $primary;
            border-color: $primary;
        }
    }

    .ant-modal-title{
        color: $primary;
    }
    .ant-btn-primary{
        background: $primary !important;
    }
    .data-name{
        color: $primary;
        font-weight: bold;
    }

    //radio-color
    .ant-radio-checked .ant-radio-inner{
        border-color: $primary !important ;
    }
    
    .ant-radio-checked .ant-radio-inner:after{
        background-color: $primary;
    }
    
    .ant-radio:hover .ant-radio-inner {
        border-color: $primary ;
    }
    .ant-radio-checked .ant-radio-inner:focus{
        border-color: $primary;
    }
}