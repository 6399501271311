@import '../../contants/variables';

.formModal{
    .form-container{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .form-label{
            margin: 0;
            width: 40%;
            span{
                color : $red;
            }
        }

        .upload-container{
            height: 40px;
            width: 80.2%;
            border:1px dashed $gray;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 20px;
            cursor: pointer;            
            svg{
                height: 20px;
                width: 20px;
                color: $gray;
                margin-right: 10px;
            }
            .banner-p{
                font-weight: bold;
                font-size: 12px;
                color: $gray;
                margin:0;
            }
        }
        &.photo-container {
            // margin-bottom: unset;
        }
        &.label-container {
            margin-bottom: unset;
            p {
                margin: 0;
            }
        }
    }
    .image-preview-container{
        width: 100%;
        overflow: hidden;
        margin-bottom: 10px;
        img{
            width: 100%;
        }
    }

    .react-datepicker__input-container {
        width: inherit;
      }
      
    .ant-space,
    .ant-space-item,
    .react-datepicker-wrapper {
        width: 100%;
    }  

    .ant-modal-title{
        color: $primary;
    }
    .ant-btn-primary{
        background: $primary !important;
    }
    .ant-btn-primary:disabled{
        background: $lightgray !important;
        color: $white !important;
        border-color: $gray !important;
    }
    .data-name{
        color: $primary;
        font-weight: bold;
    }

    .ant-picker-clear{
        display: none !important;
    }
}