@import "../contants/variables";

.career-table-container {
  margin: 30px 20px;
  padding: 20px;
  background: $white;
  border-radius: 5px;
  box-shadow: 2px 2px 4px $gray;
  position: relative;

  .add-button-container {
    margin-right: 20px;
    display: flex;
    align-items: center;
    svg {
      height: 20px;
      width: 20px;
      margin-right: 5px;
    }
  }

  .table-div {
    margin-top: 30px;
  }

  .search-container {
    margin-top: 40px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .table-title-icon {
    position: absolute;
    top: -30px;
    padding: 10px;
    border-radius: 5px;

    &.primary {
      background: $primary;
    }

    svg {
      height: 70px;
      width: 70px;
      color: $white;
    }
  }

  .table-title-text {
    margin-left: 100px;
    font-weight: bold;
    font-size: 25px;

    &.primary {
      color: $primary;
    }
  }
}
