@import '../../contants/variables';

.formModal{

    .form-container{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        &.contact-number {
            margin-bottom: 0;
        }
        &.description {
            margin-bottom: 10px;
            p {
                margin: 0;
            }
        }

        &.error-content {
            margin-top: -10px;
            margin-bottom: 10px;
            p {
                margin: 0;
            }
        }

        &.tags-container {
            margin: 0;
            p {
                margin: 0;
            }
        }

        .form-label{
            margin: 0;
            width: 40%;
            span{
                color : $red;
            }
        }
    }

    .react-tel-input{
        width: 100% !important;
        // height: 100% !important;
        // z-index: 10;
        .form-control{
            width: 100% !important;
            // height: 100% !important;
            border: 1px solid #e5e5ea !important;
        }
        .form-control:disabled{
            background-color: $offwhite;
        }
    }

    .permision-label {
        span{
            color : $red;
        }
    }
    
    .user-type-radio {
        margin: 10px 0px;
        .ant-radio-checked .ant-radio-inner{
            border-color: $primary !important ;
        }
        
        .ant-radio-checked .ant-radio-inner:after{
            background-color: $primary;
        }
        
        .ant-radio:hover .ant-radio-inner {
            border-color: $primary ;
        }
        .ant-radio-checked .ant-radio-inner:focus{
            border-color: $primary;
        }
    }
    .permission-checklist{
        padding: 10px;

        .ant-checkbox-checked .ant-checkbox-inner,
        .ant-checkbox-checked .ant-checkbox-inner:focus {
            background-color: $primary;
            border-color: $primary;
        }
    }

    .ant-modal-title{
        color: $primary;
    }
    .ant-btn-primary{
        background: $primary !important;
    }
    .data-name{
        color: $primary;
        font-weight: bold;
    }
    .image-preview-container{
        width: 100%;
        overflow: hidden;
        img{
            width: 100%;
        }
    }

    .attached-container {
        margin-bottom: 15px;
        p {
            margin: 5px 0;
        }
        .attached-preview-container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100px;
            width: 100px;
            border: 1px dashed $gray;
            cursor: pointer;
        }
    }

}

.photo-viewer-modal {
    .ant-modal-title {
        text-align: center;
    }
    .photo-viewer-modal-content {
        display: grid;
        place-items: center;
        min-height: 600px;
        img {
            display: block; 
            width: 100%; 
            height: auto; 
            border: 1px solid $gray;
            border-radius: 10px;
        }
    }
}