@import '../contants/variables';

.profile-container{
    margin: 20px;
    background: $white;
    padding: 20px;
    border-radius: 5px;

    .line{
        margin: 20px 0px;
    }

    .form-label{
        .role{
            color: $primary;
            font-weight: bold;
        }
    }

    .header-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        // margin-bottom: 20px;
        .headers {
            font-size: 20px;
            font-weight: bold;
        }
    }

    .action-button-container{
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding-top: 35px;
        button{
            margin-left: 10px;
            width: 180px !important;
        }
    }
    
    .permissions-container{
        .permission-container{
            background-color: $offwhite;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            svg{
                color: $green;
            }
        }
    }

    .contacts-container{
        margin-top: 10px;
        .contacts-length{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 5px 0px;
            padding: 0px 10px;
            font-weight: bold;
            p{
                margin:0
            }
        }
        .contact-div{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 5px 0px;
            padding: 0px 10px;
            &:hover{
                background: $offwhite;
            }
            p{
                margin:0;
            }

            .contact-badge.active{
                color: $green;
                padding: 5px 0px;
                font-weight: 500;
                cursor: default;
                &:hover{
                    text-decoration: none;
                }
            }

            .contact-badge{
                padding: 5px 0px;
                font-weight: 500;
                cursor: pointer;

                .make-active {
                    color: $primary;
                    margin-left: 10px;
                    &:hover{
                        text-decoration: underline;
                    }
                }

                .remove {
                    color: $red;
                    margin-left: 10px;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }


        }
    }
}