@import '../../contants/variables';

.formModal{
    .headers{
        font-size: 20px;
        font-weight: 500;
        color: $primary;
    }
    .contacts-chip-container{
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        .contact-container{
            display: inline-flex;
            align-items: center;
            margin:10px 10px 0px 0px;
            border-radius: 5px;
            padding: 5px 10px;

            &.phone{
                background-color: $primary;
                margin: 0;
            }

            &.email{
                background-color: $secondary;
            }

            &.contact-number {
                margin-bottom: 0;
            }
            
            &.description {
                margin-bottom: 10px;
                p {
                    margin: 0;
                    line-height: 1em;
                }
            }

            svg{
                color: $white;
                cursor: pointer;
                transform: scale(1);
                transition: 0.3;
                &:hover{
                    transform: scale(1.2);
                    transition: 0.3;
                }
            }
            p{
                margin: 0px 0px 0px 10px;
                color: $white;
            }
        }
    }
}

.rental-form-container{
    margin: 20px;
    padding: 20px;
    background: $white ;
    position: relative;

    .table-title-icon{
        position: absolute;
        top: -30px;
        padding: 10px;
        border-radius: 5px;

        &.add {
            background :$primary;
        }
        &.edit {
            background :$secondary;
        }

        svg{
            height: 70px;
            width: 70px;
            color: $white;
        }
    }
    .table-title-text{
        margin-left: 100px;
        font-weight: bold;
        font-size: 25px;

        &.add {
            color :$primary;
        }
        &.edit {
            color :$secondary;
        }
    }
    .header-container{
        margin:10px 0px;
        .header{
            font-weight: bold;
            color: $black;
        }
        .sub-header{
            font-weight: 500;
            color: gray;
        }
    }
    .form-fields{
        padding: 10px 20px 10px 0px;
        .form-field-label{
            margin-bottom: 10px;

            span{
                color:$red
            }
        }
    }

    .upload-container{
        height: 200px;
        border:2px dashed $gray;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.col-div{
            margin-right: 20px;
            margin-bottom: 20px;
        }

        svg{
            height: 50px;
            width: 50px;
            color: $gray;
        }
        p{
            margin-top: 10px;
            font-weight: bold;
            font-size: 16px;
            color: $gray;
        }
    }

    .image-container{
        height: 200px;
        border:2px solid $black;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img{
            max-height: 100%;
            max-width: 100%;
            object-position: center;
            object-fit: cover;
        }
        &.col-div{
            margin-right: 20px;
        }

        .removeIcon{
            height: 30px;
            width: 30px;
            background: $red;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0%;
            right: 0%;
            cursor: pointer;
            svg{
                color: $white;
                height: 20px;
                width: 20px;
            }
        }
        .downloadIcon{
            height: 30px;
            width: 30px;
            background: $green;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 31px;
            right: 0%;
            cursor: pointer;
            svg{
                color: $white;
                height: 20px;
                width: 20px;
            }
        }
    }

    .button-container{
        display: flex;
        justify-content: flex-end;
        .button{
            width: 250px;
        }
    }

    .upload-error-text{
        margin-bottom: 10px;
        color: $red;
    }

    .react-datepicker__input-container {
        width: inherit;
    }
      
    .ant-space,
    .ant-space-item,
    .react-datepicker-wrapper {
        width: 100%;
    }

    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-checked .ant-checkbox-inner:focus {
        background-color: $primary;
        border-color: $primary;
    }
}

//rich text editor
.wrapper-class {
    padding: 1rem;
    border: 1px solid $lightgray;
}
.editor-class {
    background-color:$white;
    padding: 1rem;
    border: 1px solid $lightgray;
}
.toolbar-class {
    border: 1px solid $lightgray;
    .rdw-option-wrapper{
        height: 30px;
        width: 30px;
    }
}

.ant-picker-clear{
    display: none !important;
}