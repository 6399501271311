@import '../contants/variables';

.trigger-container{
    padding: 20px;
    background-color: $white;
    margin: 20px;
    border-radius: 5px;
    .form-div{
        width: 100%;
    }
    .form-sub-label{
        color: $red;
        margin: 5px 0px 0px 0px;
    }
    .btn-div{
        display: flex;
        flex:1;
        justify-content: flex-end;
        .btn{
            margin-top: 20px;
            width: 200px;
        }
    }
    .numbers-container{
        margin-top: 20px;
        padding: 20px;
        // width: 50%;
        border: 2px dashed $black;

        .phone-chip{
            background-color: $secondary;
            padding: 5px 10px;
            display: flex;
            align-items: center;
            border-radius: 50px;
            transition: 0.3s;
            transform: scale(1);

            p{
                margin:0px 0px 0px 10px;
                color: $white;
                font-size: 18px;
            }
            svg{
                color: $white;
                height: 20px;
                width: 20px;
                cursor: pointer;
            }

            &:hover{
                transition: 0.3s;
                transform: scale(1.1);
            }
        }
    }
}
