.content-container{
  background-image: linear-gradient(#7EE3F3,#f2f2f2);
  min-height: 100vh;
}
.side-nav-container{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  width: 300px;
}

.main-container {
  margin-left: 300px;
}

.scrollable-table-container {
  overflow-x: auto;
  white-space: nowrap;
  margin: 20px 0;
  
  .ant-table-wrapper {
    min-width: 1000px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    tr, td {
      text-wrap: wrap;
      padding: 10px;
    }
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
  }
}



@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 991px) {
  .side-nav-container{
    width: 250px;
  }

  .main-container {
    margin-left: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .side-nav-container{
    /* width: 250px; */
    display: none;
  }

  .main-container {
    /* margin-left: 250px; */
    margin-left: 0;
  }
}


/* // 1200
// 991
// 768
// 600
// 480
// 320 */
