@import "../../contants/variables";

.tags-container {
  margin-top: 16px;
  .tag-container {
    background: $primary;
    padding: 5px 10px;
    color: $white;
    display: inline-flex;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
    p {
      margin: 0;
    }
    svg {
      height: 20px;
      width: 20px;
      margin-right: 5px;
      cursor: pointer;
    }
  }
}

.upload-container {
  height: 40px;
  width: 100%;
  border: 2px dashed $gray;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  cursor: pointer;

  svg {
    height: 20px;
    width: 20px;
    color: $gray;
    margin-right: 10px;
  }
  .banner-p {
    font-weight: bold;
    font-size: 12px;
    color: $gray;
    margin: 0;
  }
}

.image-preview-container {
  width: 100%;
  overflow: hidden;
  img {
    width: 100%;
  }
}
