$primary:#254A7C;
$secondary:#20A8F4;
$tertiary:#337AB7;
$red:#EA3636;
$yellow:#E3D337;
$green:#2C9C22;
$gray:#808080;
$lightgray:#C2BEBE;
$black: #4F4A4A;
$white: #fff;
$offwhite:#f2f2f2;