@import '../../contants/variables';

.accessory-form-container{
    margin: 20px;
    padding: 20px;
    background: $white ;
    position: relative;
    .table-title-icon{
        position: absolute;
        top: -30px;
        padding: 10px;
        border-radius: 5px;

        &.add {
            background :$primary;
        }
        &.edit {
            background :$secondary;
        }

        svg{
            height: 70px;
            width: 70px;
            color: $white;
        }
    }

    .table-title-text{
        margin-left: 100px;
        font-weight: bold;
        font-size: 25px;

        &.add {
            color :$primary;
        }
        &.edit {
            color :$secondary;
        }
    }
    .form-container{
        margin-bottom: 20px;
        .form-fields{
            padding: 10px 20px 10px 0px;
            display: flex;
            .form-field-label{
                margin-bottom: 10px;
                width: 200px;
                span{
                    color:$red
                }
            }
        }
    }

    .tags-container{
        margin-left: 145px;
        .tag-container{
            background: $primary;
            padding: 5px 10px;
            color: $white;
            display: inline-flex;
            border-radius: 5px;
            margin-right: 10px;
            margin-bottom: 10px;
            p{
                margin:0;
            }
            svg{
                height: 20px;
                width: 20px;
                margin-right: 5px;
                cursor: pointer;
            }
        }
    }

    .upload-container{
        height: 200px;
        border:2px dashed $gray;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.col-div{
            margin-right: 20px;
            margin-bottom: 20px;
        }

        svg{
            height: 50px;
            width: 50px;
            color: $gray;
        }
        p{
            margin-top: 10px;
            font-weight: bold;
            font-size: 16px;
            color: $gray;
        }
    }

    .local-image{
        width: 100%;
        height: auto;
        overflow: hidden;
        position: relative;
        img{
            width: 100%;
            height: auto;
        }
        .erase-image{
            position: absolute;
            right: 0;
            top: 0;
            background: $white;
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            svg{
                height: 30px;
                width: 30px;
                &:hover{
                    color: $red;
                }
            }
        }
    }

    .btn-div{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 10px;
        .btn{
            margin-left: 15px;
            width: 250px;
        }
    }

      .permission-checklist{
        padding: 10px;

        .ant-checkbox-checked .ant-checkbox-inner,
        .ant-checkbox-checked .ant-checkbox-inner:focus {
            background-color: $primary;
            border-color: $primary;
        }
    }
}

.upload-header {
    margin-top: 40px;
}
.header-text {
    font-weight: bold;
}
.header-text span {
    color: red;
}

.icon-button-div {
    height: 30px;
    width: 30px;
    border:1px solid $offwhite;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .icon-button{
        margin:0;

        svg{
            margin-top: 8px;
        }
    }
    &:hover{
        box-shadow: 1px 1px 2px $lightgray;
    }
}

.section-container{
    background: $offwhite;
    margin: 5px 0px;
    padding: 5px 10px;
    border-radius: 5px;
    p{
        margin: 0;
    }

    .icon-container{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .icon-button{
            height: 25px;
            width: 25px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: scale(1);
            transition: 0.2s;
            cursor: pointer;
            margin-left: 10px;
    
            &:hover{
                background: $offwhite;
                transform: scale(1.2);
                transition: 0.2s;
            }
            &.edit{
                svg{
                    color: $secondary;
                }
            }
            &.delete{
                svg{
                    color: $red;
                }
            }
    
            svg{
                height: 20px;
                width: 20px;
            }
        } 
    }
}

.confirm-delete{
    .yes{
        color: $green;
        cursor: pointer;
        font-weight: bold;
        &:hover{
            text-decoration: underline;
        }
    }
    .no{
        color: $red;
        cursor: pointer;
        font-weight: bold;
        &:hover{
            text-decoration: underline;
        }
    }
}



.formModal{
    .headers{
        font-size: 20px;
        font-weight: 500;
        color: $primary;
    }
    .contacts-chip-container{
        margin-bottom: 20px;
        .contact-container{
            display: inline-flex;
            align-items: center;
            margin:10px 10px 0px 0px;
            border-radius: 5px;
            padding: 5px 10px;

            &.phone{
                background-color: $primary;
            }

            &.email{
                background-color: $secondary;
            }

            svg{
                color: $white;
                cursor: pointer;
                transform: scale(1);
                transition: 0.3;
                &:hover{
                    transform: scale(1.2);
                    transition: 0.3;
                }
            }
            p{
                margin: 0px 0px 0px 10px;
                color: $white;
            }
        }
    }
}

.accessory-form-container{
    margin: 20px;
    padding: 20px;
    background: $white ;
    position: relative;

    .table-title-icon{
        position: absolute;
        top: -30px;
        padding: 10px;
        border-radius: 5px;

        &.add {
            background :$primary;
        }
        &.edit {
            background :$secondary;
        }

        svg{
            height: 70px;
            width: 70px;
            color: $white;
        }
    }
    .table-title-text{
        margin-left: 100px;
        font-weight: bold;
        font-size: 25px;

        &.add {
            color :$primary;
        }
        &.edit {
            color :$secondary;
        }
    }
    .header-container{
        margin:10px 0px;
        .header{
            font-weight: bold;
            color: $black;
        }
        .sub-header{
            font-weight: 500;
            color: gray;
        }
    }
    .form-fields{
        padding: 10px 20px 10px 0px;
        .form-field-label{
            margin-bottom: 10px;

            span{
                color:$red
            }
        }
    }

    .upload-container{
        height: 200px;
        border:2px dashed $gray;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.col-div{
            margin-right: 20px;
            margin-bottom: 20px;
        }

        svg{
            height: 50px;
            width: 50px;
            color: $gray;
        }
        p{
            margin-top: 10px;
            font-weight: bold;
            font-size: 16px;
            color: $gray;
        }
    }

    .image-container{
        height: 200px;
        border:2px solid $black;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img{
            max-height: 100%;
            max-width: 100%;
            object-position: center;
            object-fit: cover;
        }
        &.col-div{
            margin-right: 20px;
        }

        .removeIcon{
            height: 30px;
            width: 30px;
            background: $red;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0%;
            right: 0%;
            cursor: pointer;
            svg{
                color: $white;
                height: 20px;
                width: 20px;
            }
        }
        .downloadIcon{
            height: 30px;
            width: 30px;
            background: $green;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 31px;
            right: 0%;
            cursor: pointer;
            svg{
                color: $white;
                height: 20px;
                width: 20px;
            }
        }
    }

    .button-container{
        display: flex;
        justify-content: flex-end;
        .button{
            width: 250px;
        }
    }

    .upload-error-text{
        margin-bottom: 10px;
        color: $red;
    }

    .react-datepicker__input-container {
        width: inherit;
    }
      
    .ant-space,
    .ant-space-item,
    .react-datepicker-wrapper {
        width: 100%;
    }

    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-checked .ant-checkbox-inner:focus {
        background-color: $primary;
        border-color: $primary;
    }
}

//rich text editor
.wrapper-class {
    padding: 1rem;
    border: 1px solid $lightgray;
}
.editor-class {
    background-color:$white;
    padding: 1rem;
    border: 1px solid $lightgray;
}
.toolbar-class {
    border: 1px solid $lightgray;
    .rdw-option-wrapper{
        height: 30px;
        width: 30px;
    }
}

.ant-picker-clear{
    display: none !important;
}