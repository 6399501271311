@import '../contants/variables';

.container{
    margin: 20px 10px;
}

.car-lists-card-container{
    height: auto;
    padding: 10px;

    .card-body{
        background-color: $white;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        box-shadow: 2px 2px 6px $gray;
        overflow: hidden;
    }
}

.chart-lists-card-container{
    // height: 500px;
    padding: 10px;

    .chart-body{
        background-color: $white;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        box-shadow: 2px 2px 6px $gray;
        padding: 20px;
        .header{
            color: $primary;
            font-weight: bold;
            font-size: 16px;
        }
    }
}