@import '../contants/variables';



.deleteModal{

    .ant-modal-title{
        color: $primary;
    }
    .ant-btn-primary{
        background: $primary !important;
    }
    .data-name{
        color: $primary;
        font-weight: bold;
    }
}

.smsModal{

    .ant-modal-title{
        color: $primary;
    }
    .ant-btn-primary{
        background: $primary !important;
    }
    .data-name{
        color: $primary;
        font-weight: bold;
    }
    .message-container{
        margin-bottom: 20px;
    }
    .ant-btn-primary:disabled{
        background: $lightgray !important;
        color: $white !important;
        border-color: $gray !important;
    }
}

.banner{
    .view-banner-container{
        height: auto;
        width: 100%;
        overflow: hidden;
        img{
            width: 100%;
            height: auto;
        }
    }
}

.tech-specs-container,
.spec-container{
    height: 100%;  
}

.preview-modal{
    .ant-modal-title{
        color: $primary;
    }
    .ant-btn-primary{
        background: $primary !important;
    }
    .data-name{
        color: $primary;
        font-weight: bold;
    }
    .product-container{
        padding: 10px;
        border:none;
        .image-container{
            width: 100%;
            overflow: hidden;
            height: 300px;
            .ant-carousel{
                height: 100% !important;
            }
            img{
                width: 100%;
                height:100%;
            }
        }
        .header-name-container{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .type{
                color: $gray;
                font-size: 12px;
                margin-bottom: -10px;
                // line-height: 0px;
            }
            .name{
                font-size: 20px;
                font-weight: bold;
            }
            .price{
                font-size: 20px;
                color: $primary;
                font-weight: bold;
            }
            .slider-icon-container{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .slider-icon{
                    cursor: pointer;
                    background: $lightgray;
                    height: 15px;
                    width: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 5px;
                    margin-top: 5px;
                    &:hover{
                        background: $gray;
                    }
                    svg{
                        color: $white;
                        height: 10px;
                        width: 10px;
                    }
                }
            }
        }
        .header-section{
            font-weight: bold;
            color: $black;
            margin: 10px 0px;
        }
    
        .tech-specs-container{
            display: flex;
            align-items: flex-start;
            background: $offwhite;
            padding: 10px;
            height: 100%;
            .tech-spec-label{
                margin:0;
                font-size: 10px;
                margin-left: 5px;
                font-weight: bold;
            }
            .tech-spec-value{
                margin-left: 5px;
                font-weight: bold;
                color: $primary;
            }
            
        }
        .spec-container{
            display: flex;
            align-items: center;
            background: $offwhite;
            padding: 10px;
            svg{
                color: $black;
            }
            p{
                color: $black;
                margin: 0px 0px 0px 5px;
            }
            .price-icon{
                background: $black;
                padding: 0px 5px;
                margin: 2px 0px;
                border-radius: 2px;
                text-align: center;
                p{
                    color: $white;
                    font-weight: bold;
                    font-size: 12px;
                    margin: 0px;
                }
            }
            .price-value{
                font-weight: bold;
                color: $primary;
            }
        }
        
        
    }
}

.no-image-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: $lightgray;
    padding:20px;
    height: 300px;

    svg{
        width: 80px;
        height: 80px;
        color: $white;
    }
    p{
        color: $white;
        font-size: 20px;
        font-weight: bold;
    }
}


.view-article-container{
    .article-header{
        .article-thumbnail{
            height: auto;
            width: 100%;
            overflow: hidden;
            img{
                width: 100%;
            }
        }
        .article-title{
            font-size: 30px;
            margin: 15px 0px;
            color: $black;
            font-weight: bold;
        }

        .article-info{
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $gray;
            margin-bottom: 20px;
            p{
                margin: 0;
            }
            .section{
                text-transform: uppercase;
                font-weight: bold;
                color: $yellow;
            }
            .author{
                font-weight: bold;
                color: $black;
            }
            .article-share{
                display: flex;
                align-items: center;
                color: $secondary;
                p{
                    margin: 0px 0px 0px 10px;
                }
            }
        }
    }
    
    .article-tags{
        p{
            margin: 0;
        }
        .tag-container{
            display: inline-block;
            padding: 10px;
            text-align: center;
            background: $offwhite;
            margin: 10px 10px 0px 0px;
            border-radius: 5px;
            p{
                margin: 0;
            }
        }
    }
}

.view-rental-container{
    .product-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .share{
            display: flex;
            align-items: center;
            p{
                margin: 0px 0px 0px 10px;
                color: $secondary;
            }
            svg{
                color: $secondary;
            }
        }
        .product-name{
            font-size: 20px;
            color: $black;
            font-weight: bold;
            margin: 0;
        }
        .vehicle-type{
            font-size: 15px;
            color: $gray;
            font-weight: 500;
        }
    }
    
    .rental-pricing-div{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        background-color: $offwhite;
        border-radius: 5px;
        padding: 10px;
        p{
            margin: 0;
        }
        .label{
            font-weight: 500;
        }
        .value{
            font-weight: bold;
            font-size: 16px;
            color: $green;
            span{
                font-size: 10px;
                color: $gray;
            }
        }
    }

    

    .specs-container{
        margin-top: 20px;
        .spec-container{
            height: 100%;
            background: $offwhite;
            border-radius: 5px;
            padding: 10px 20px;
            .label{
                color: $black;
                font-size: 10px;
                font-weight: 500;
                margin: 0;
            }
            .value{
                margin: 0;
                color: $primary;
                font-weight: bold;
            }
        }
    }

    .image-container{
        width: 100%;
        overflow: hidden;
        height: 250px;
        background: $offwhite;
        .ant-carousel{
            height: 100% !important;
            width: 100% !important;
        }
        img{
            width: 100%;
            height:100%;
        }
    }
    .slider-icon-container{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .slider-icon{
            cursor: pointer;
            background: $lightgray;
            height: 15px;
            width: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 5px;
            margin-top: 5px;
            &:hover{
                background: $gray;
            }
            svg{
                color: $white;
                height: 10px;
                width: 10px;
            }
        }
    }
    .rental-desc{
        margin: 10px 0px 0px 0px;
        font-size: 18px;
        font-weight: 500;
        color: $black;
    }

    .company-container{
        margin-top: 20px;
        background-color: $offwhite;
        border-radius: 5px;
        overflow: hidden;
        .company-header{
            background-color: $primary;
            padding: 10px;
            p{
                font-size: 16px;
                font-weight: 500;
                color: $white;
                margin: 0;
            }
        }
        .company-details{
            padding: 10px;
            .company-name{
                font-size: 16px;
                font-weight: bold;
                color: $primary;
            }
            .details-container{
                display: flex;
                align-items: center;
                margin-top: 10px;
                &.multi{
                    align-items: flex-start;
                    svg{
                        margin-top: 5px;
                    }
                }
                p{
                    margin: 0px 0px 0px 10px;
                }
            }
        }
    }
    .ad-container{
        height: 300px;
        background-color: $lightgray;
        margin-top: 10px;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        div{
            border: 5px solid $gray;
            width: 100%;
            text-align: center;
            padding: 20px;
            p{
                margin: 0;
                font-weight: bold;
                color: $gray;
            }
        }
    }
}

//rich text editor
.preview-modal.article,
.preview-modal.rental{
    .rdw-editor-toolbar{
        display: none !important;
    }
}

.chip-tooltip-container{
    p{
        margin: 0;
        color: $primary;
    }
}
.chip-permission-container{
    display: flex;
    background: $offwhite;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-radius: 50px;
    cursor: pointer;
    

    &:hover{
        background: $lightgray;
    }

    .chip-text{
        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        width: 80%;
        p{
            margin:0
        }
    }
    .chip-count{
        background: $primary;
        padding: 5px;
        color: $white;
        border-radius: 50%;
        height: 25px;
        width: 25px;
        display: flex;
        align-items: center;
        font-size: 10px;
    }
}


.timer-countdown{
    color: $primary;
    font-weight: bold;
    font-size: 20px;
    margin: 0;
    span{
        color: $red;
    }
}